.bottom-nav-container {
    background: #3C3C3C;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.toggle-container {
    cursor: pointer;
    width: 132px;
    height: 36px;
    margin-left: 12px;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 18px;
    display: flex;
    justify-content: center;
}

.toggle-slider {
    transition: all .2s ease-in-out;
    transform: translateX(-30px);
    width: 69px;
    height: 95%;
    margin: 1px;
    background: #7876D9;
    border-radius: 16px;
}

.toggle-slider.active {
    transform: translateX(29px);
}

.bottom-nav-slider-text {
    position: absolute;
    width: 132px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    color: white;
    margin-right: 4px;
    margin-left: 4px;
    font-size: 12px;
    z-index: 10;
}

.text-small {
    font-size: 14px;
}

.bottom-nav-container * svg {
    margin-right: 8px;
}

.bottom-nav-container * button[disabled] {
    opacity: 0.2;
}
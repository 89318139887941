.tool-nav {
    width: 100%;
    height: 52px;
    background: #3C3C3C;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tool-nav p {
    color: white;
    font-size: 16px;
}

/* BUTTONS */

.btn-long,
.btn-icon {
    border-radius: 4px;
    border-color: #7876D9;
    cursor: pointer;
    transition: all 0.2s;
    margin: 8px;
    outline: none;
}

.tool-label {
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: black;
    height: 36px;
    padding-top: 8px;
}

@media screen and (max-width: 768px) {
    button.btn-long,
    button.btn-icon {
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 4px;
        margin-right: 4px;
    }
}

button.btn-long:active, button.btn-icon:active {
    background: #5a5ab4;
}

.btn-long {
    padding-left: 32px;
    padding-right: 32px;
    height: 36px;
    font-size: 16px;
}

.btn-icon {
    height: 36px;
    max-height: 36px;
    padding: 0 12px;
    font-size: 16px;
}

.btn-icon-title i {
    margin-right: 8px;
}

@media screen and (max-width: 1024px) {
    .btn-icon-title i {
        margin-right: 0;
    }
    .btn-icon-title {
        width: 36px;
    }
}

.btn-solid {
    background: #7876D9;
    color: white;
}
.btn-solid:hover {
    background: #605EC1;
    border-color: #605EC1;
}

.btn-outlined {
    background:transparent;
    color: white;
}
.btn-outlined:hover {
    background:#7876D9;
}

.btn-disabled {
    background: #828282;
    color: #B3B3B3;
}

.btn-icon-purple {
    color: #7876D9;
    background: transparent;
    border: none;
    width: 36px;
    height: 36px;
    cursor: pointer;
}
.btn-icon-purple:hover {
    color: #605EC1;
}
.btn-icon-purple i {
    font-size: 16px;
}

/* INPUT */
.tool-title {
    color: white;
    display: flex;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tool-input {
    height: 34px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px #828282;
    background: transparent;
    margin-left: 8px;
    padding: 0 8px;
    color: white;
    font-size: 16px;
    width: 230px;
    display: flex;
    align-self: center;
    transition: border-color 0.2s;
}
.tool-input:hover {
    border-color:#7876D9;
}
.tool-input[disabled] {
    color: #828282;
}
.tool-input[disabled]:hover {
    border-color:#828282 ;
}

button.input-attach-right {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

button[disabled] {
    background: grey;
    pointer-events: none;
    border-color: grey;
}
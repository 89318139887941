@media screen and (max-width: 768px) {
    .mobile-hidden {
        display: none;
    }

    .mobile-space-between {
        justify-content: space-between
    }

    .mobile-full-width {
        width: 100%;
    }
    .mobile-row-reverse {
        flex-direction: row-reverse;
    }
}

@media screen and (max-width: 1024px) {
    .tablet-hidden {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .desktop-hidden {
        display: none;
    }
}
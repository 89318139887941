/* -------------- GENERAL -------------- */

#root {
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

body,html {
  height: 100%;
}

.editor-view {
  position: relative;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.outer-editor-view {
  position: relative;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  div.editor-view {
    position: absolute;
    flex-grow: 1;
    height: 100%;
  }
  button.view-expander {
    height: calc(100% - 165px);
  }
}

.editor-view > div {
  width: 100%;
  height: 100%;
}

.select-mode {
  height: 35px;
  padding: 5px 7px;
  width: 150px;
  border-radius: 4px;
  border-color: #5bc0de;
}
.raw-json {
  word-wrap: break-word;
  white-space: pre-wrap;
  background: white;
  border: none;
}

#json-editor * .ace_gutter-cell {
 color: #606060;
}

input:focus {
  outline: none;
}

/* -------------- TYPOGRAPHY -------------- */

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #3C3C3C;
}

a {
  color: #222222;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  font-weight: 500;
}
a:hover {
  color: #7876D9;
}

h1 {
  font-size: 22px;
  font-weight: 400;
}

h2 {
  font-size: 18px;
  margin: 8px 0 4px 0;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 12px;
}

.text-gray {
  color: #828282;
}

/* -------------- NAVBAR TOP -------------- */

@media screen and (max-width: 768px) {
  div.navbar-light {
    padding-left: 12px;
  }
  div.nav-title {
    display: none;
  }
  img.logo {
    height: 24px;
  }
}

.navbar-top {
  border-bottom: solid 1px #ececec;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 28px;
  margin: 16px auto;
}

.nav-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-title-icon {
  color: #7876D9;
  padding: 12px;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
.nav-title-icon:hover {
  color: #605EC1;
}

.nav-title input {
    height: 34px;
    border-radius: 4px;
    border: solid 1px #828282;
    background: transparent;
    margin-left: 8px;
    padding: 0 8px;
    color: #3C3C3C;
    font-size: 16px;
    width: 240px;
    display: flex;
    align-self: center;
    transition: border-color 0.2s;
}
.nav-title input:hover {
    border-color:#7876D9;
}

/* -------------- BUTTONS -------------- */

@media screen and (max-width: 768px) {
  button.top-nav-button {
    padding-left: 16px;
    padding-right: 16px;
  }
}

button.top-nav-new-button {
  width: 60px;
  height: 60px;
  border: none;
  border-left:solid 1px #DDDDDD;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
button.top-nav-new-button:hover {
  background: #ECECEC;
}

.top-nav-button {
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 8px 16px;
  margin: 0 8px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.top-nav-button:hover {
  background: #ECECEC;
}

.top-nav-button:active {
  background: #D1D1D1;
}

.btn-social {
  height: 42px;
  width: 220px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 6px;
  transition: all 0.2s ease-in-out;
}
.btn-social i {
  font-size: 20px;
  padding: 0 12px 0 24px;
}

.btn-social--twitter {
  background: #43A1EC;
}
.btn-social--twitter:hover {
  background: #3386C8;
}

.btn-social--facebook {
  background: #4669AD;
}
.btn-social--facebook:hover {
  background: #294B8D;
}

.btn-social--linkedin {
  background: #0074B1;
}
.btn-social--linkedin:hover {
  background: #045B89;
}


/* -------------- TOGGLE JSON VIEW -------------- */

.view-expander {
  height: calc(100vh - 113px);
  background: #222222;
  color: white;
  width: 12px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  transition: background 0.2s ease-in-out;
}

.view-expander:hover {
  background: #605EC1;
  cursor: pointer;
}

.view-expander:active {
  background: #A3A2FF;
}

.view-expander > img {
  width: 13px;
  margin-right: 4px;
  height: 11px;
}

.flip-x-scale {
  transform: scaleX(-1);
}

.modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
    right: 0;
}

.modal-trap {
    transition: opacity .2s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    opacity: 0;
    z-index: 5;
}

.modal-trap.active {
    opacity: 0.7;
}

.modal-body {
    -webkit-transition: all .3s;
    transition: all .3s;
    width: 600px;
    max-width: calc(100% - 28px);
    background: #fff;
    border-radius: 8px;
    opacity: 0;
    z-index: 6;
    position: fixed;
    -webkit-transform: translateY(-48px);
    transform: translateY(-48px);
}

.modal-body.active {
    transform: translateY(0);
    opacity: 1;
}

/* -------------- HEADER -------------- */

.modal-header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    padding-right: 8px;
}

.modal-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #3C3C3C;
}

.modal-border {
    border-bottom: 1px solid #dddddd;
    margin: 0 20px;
}

/* -------------- CONTENT -------------- */

.modal-content {
    padding: 20px 32px;
    max-height: 480px;
    overflow-y: scroll;
}
.modal-content p {
    margin: 0 0 12px 0;
}

.modal-content ul,
.modal-content li {
    margin: 8px 0;
}

.modal-content ul {
  padding-left: 20px;
}

.modal-content .tool-input {
    color: #828282;
}

/* -------------- BUTTONS -------------- */

.btn-close {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-in-out;
}
.btn-close:hover {
    background: #ECECEC;
}
.btn-close i {
    font-size: 20px;
    color: #3C3C3C;
}

/* -------------- FOOTER -------------- */

.modal-footer{
    text-align: center;
    color: #828282;
    font-size: 14px;
    margin: 20px 0;
  }